import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Lab3d from "../components/lab-3d-scene"
import { StaticImage } from "gatsby-plugin-image"

import { RoughNotation } from "react-rough-notation"

const LabPage = () => {

    // const [ selection, setSelection ] = useState([]);
    // const [ projectList, setProjectList ] = useState(Object.keys(research));

    const [cameraAngle, setCameraAngle] = useState([0,0]);

    useEffect(() => {
      const mainSection = document.querySelector('.main-section');
      const mainSectionHeight = mainSection.offsetHeight;
      const mainSectionWidth = mainSection.offsetWidth;
      mainSection.addEventListener('mousemove', (e)=>{
        setCameraAngle([-1 * e.clientY/mainSectionHeight, -1 * e.clientX/mainSectionWidth]);
      })
    },[]);

    return (
        <>
            <Seo title="Lab" />
            <section className={`main-section fix-bottom lab-section`}>
              <div className={`three-d-back`}>
                <Lab3d cameraAngle={cameraAngle} />
              </div>
             <div className={`center-content full-height`}>
             <div className={`content`}>
               <h6 className={`primary center`}>Where chaos is organized</h6>
               <h1 className={`center`}>Experiments & More</h1>
              <div className={`content--md-center`}>
                 <p className={`primary center`}>A collection of side <RoughNotation type={`crossed-off`} show={true} color={`#171920`}>quests</RoughNotation> projects, archives, life, randomness, and more that makes me happy</p>
               </div>
             </div>
             </div>
            </section>
            <section className={`fix-bottom`}>
             <div className={`content`}>
               <div className={`center-content add-margin-bottom`}>
                 <div className={`timeline-year`}>
                   2021
                 </div>
               </div>
               <div className={`split-wrapper-2-1 add-margin-bottom`}>
                 <div className={`split`}>
                  <div className={`yt-embed`}>
                  <iframe src="https://www.youtube.com/embed/5GUQX-FHEPg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                 </div>
                 <div className={`split`}>
                   <h4>Transition</h4>
                   <p>In August 2021, I moved to Atlanta for my Masters. These are some favorite snapshots from August - December 2021.</p>
                   <div className={`stack`}>
                    {['Premiere Pro'].map((item, index)=>(
                      <div className={`stack-item`} key={index}>{item}</div>
                    ))}
                   </div>
                   <a><span className={`link data-hover`}>Watch on Youtube</span></a>
                 </div>
               </div>
               <div className={`split-wrapper-2-1 add-margin-bottom`}>
                <div className={`split`}>
                  <Link to={`/random/bus-tracking`}><div className={`data-hover`}><StaticImage src={`../images/lab/PassioGo.png`} alt={`PassioGo`} /></div></Link>
                 </div>
                 <div className={`split`}>
                   <h4>Georgia Tech's Bus Tracking App</h4>
                   <p>I redesigned Georgia Tech's official bus tracking app, PassioGo, with UI/UX improvements on features such as route searching, real-time map, and alerts. </p>
                   <div className={`stack`}>
                    {['Figma'].map((item, index)=>(
                      <div className={`stack-item`} key={index}>{item}</div>
                    ))}
                   </div>
                   <Link to={`/random/bus-tracking`}><span className={`link data-hover`}>Prototype in Action</span></Link>
                 </div>
               </div>
               
               <div className={`center-content add-margin-bottom`}>
                 <div className={`timeline-year`}>
                   2020
                 </div>
               </div>
               <div className={`split-wrapper-2-1 add-margin-bottom`}>
                 <div className={`split`}>
                 <Link to={`/random/cryptobots`}><div className={`data-hover`}><StaticImage src={`../images/lab/Cryptobots.png`} alt={`Cryptobots`} /></div></Link>
                 </div>
                 <div className={`split`}>
                   <h4>Cryptobots</h4>
                   <p>Designed a character creation/generator system as a freelance consultant for Cryptobots.</p>
                   <div className={`stack`}>
                    {['Figma'].map((item, index)=>(
                      <div className={`stack-item`} key={index}>{item}</div>
                    ))}
                   </div>
                   <Link to={`/random/cryptobots`}><span className={`link data-hover`}>All Illustrations</span></Link>
                 </div>
               </div>
               <div className={`split-wrapper-2-1 add-margin-bottom`}>
                <div className={`split`}>
                <a href={`https://www.weclothethem.com/`} target={`_blank`}><div className={`data-hover`}><StaticImage src={`../images/lab/WCT.png`} alt={`We Clothe Them`} /></div></a>
                 </div>
                 <div className={`split`}>
                   <h4>We Clothe Them</h4>
                   <p>Website Design, Branding, Videography, for a social initiative - We Clothe Them.</p>
                   <div className={`stack`}>
                    {['Figma', 'After Effects', 'Photoshop', 'Gatsby', 'GraphQL', 'Contentful'].map((item, index)=>(
                      <div className={`stack-item`} key={index}>{item}</div>
                    ))}
                   </div>
                   <a href={`https://www.weclothethem.com/`} target={`_blank`}><span className={`link data-hover`}>Visit Website</span></a>
                 </div>
               </div>
               <div className={`split-wrapper-2-1 add-margin-bottom`}>
                <div className={`split`}>
                  <a href={`https://whatdoicook.netlify.app/`} target={`_blank`}><div className={`data-hover`}><StaticImage src={`../images/lab/What Do I Cook.png`} alt={`What Do I Cook`} /></div></a>
                 </div>
                 <div className={`split`}>
                   <h4>What Do I Cook?</h4>
                   <p>Want some reassurance when you're feeling too lazy to cook? Enjoy this platform I coded in under an hour to tell you what to cook based on what's in your kitchen.</p>
                   <div className={`stack`}>
                    {['React'].map((item, index)=>(
                      <div className={`stack-item`} key={index}>{item}</div>
                    ))}
                   </div>
                   <a href={`https://whatdoicook.netlify.app/`} target={`_blank`}><span className={`link data-hover`}>Visit Website</span></a>
                 </div>
               </div>
               <div className={`center-content add-margin-bottom`}>
                 <div className={`timeline-year`}>
                   2019
                 </div>
               </div>
               <div className={`content--md-center`}><p className={`center lg add-margin-bottom`}>Archives from this year are being dug up and preserved by archaeology experts right now. This section will be updated soon.</p></div>
               <div className={`center-content add-margin-bottom`}>
                 <div className={`timeline-year`}>
                   2018
                 </div>
               </div>
               <div className={`split-wrapper-2-1 add-margin-bottom`}>
                <div className={`split`}>
                  <StaticImage src={`../images/lab/TEDx.png`} alt={`TEDx`} />
                 </div>
                 <div className={`split`}>
                   <h4>TEDx</h4>
                   <p>As the tech head for my undergrad university's TEDx club, I led the tech team to develop the event's website, mobile app, account management, and ticketing system.
                   </p>
                   <div className={`stack`}>
                    {['HTML/CSS', 'Javascript', 'Django'].map((item, index)=>(
                      <div className={`stack-item`} key={index}>{item}</div>
                    ))}
                   </div>
                   <Link to={`/coming-soon`}><span className={`link data-hover`}>Details Coming Soon</span></Link>
                 </div>
               </div>
               <div className={`split-wrapper-2-1 add-margin-bottom`}>
                <div className={`split`}>
                  <StaticImage src={`../images/lab/BeatDrop.png`} alt={`BeatDrop`} />
                 </div>
                 <div className={`split`}>
                   <h4>BeatDrop</h4>
                   <p>A design concept for a social platform that connects people with similar taste in music.</p>
                   <div className={`stack`}>
                    {['Sketch', 'Photoshop'].map((item, index)=>(
                      <div className={`stack-item`} key={index}>{item}</div>
                    ))}
                   </div>
                   <Link to={`/coming-soon`}><span className={`link data-hover`}>Details Coming Soon</span></Link>
                 </div>
               </div>
               <div className={`center-content add-margin-bottom`}>
                 <div className={`timeline-year`}>
                   2017
                 </div>
               </div>
               <div className={`content--md-center`}><p className={`center lg add-margin-bottom`}>Digging up historical data. Will be updated soon.</p></div>
             </div>
            </section>
        </>
    )
}

export default LabPage
