import React, {useState, useEffect, useRef, Suspense} from 'react';
import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber';
import { Box, OrbitControls } from '@react-three/drei';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const deg2rad = degrees => degrees * (Math.PI / 180);

let t = 0;

const SceneModel = ({path, position, scale, cameraAngle, wave}) => {
    const gltf = useLoader(GLTFLoader, path)

    if(gltf) {
        // gltf.scene.position.set(position[0], position[1], position[2])
        gltf.scene.scale.set(scale, scale, scale)
    }

    const ref = useRef(null);
    useFrame(()=> {
        ref.current.rotation.y += 0.01;
        ref.current.rotation.x = wave === 'sin' ? Math.sin((t / 20)) : Math.cos((t / 20));
        t+=0.05;
        ref.current.position.y = wave === 'sin' ? position[1] + 1.25*Math.sin((t / 8)) - cameraAngle[0] : position[1] + 1.25*Math.cos((t / 8)) - cameraAngle[0];
        ref.current.position.x = position[0] - cameraAngle[1];
        // console.log(cameraAngle);
    })
  
    return gltf ? (
        <mesh ref={ref} position={position}>
            <primitive object={gltf.scene} />
        </mesh>
    ) : null
}

const SceneBox = ({position, args, cameraAngle, wave}) => {
    const ref = useRef(null);
    useFrame(()=> {
        ref.current.rotation.x = ref.current.rotation.y += 0.01;
        t+=0.05;
        ref.current.position.y = wave === 'sin' ? position[1] + Math.sin((t / 10)) - cameraAngle[0] : position[1] + Math.cos((t / 10)) - cameraAngle[0];
        ref.current.position.x = position[0] - cameraAngle[1];
        // console.log(cameraAngle);
    })

    return (
        <>
        <Box ref={ref} position={position} args={args}>
            <meshStandardMaterial attach='material' color={'#fff'} />
        </Box>
        </>
    )
}

const Camera = ({cameraAngle}) => {
    useFrame(({camera}) => {
        camera.rotation.set(deg2rad(cameraAngle[0]? cameraAngle[0] : 0), deg2rad(cameraAngle[1]? cameraAngle[1] : 0), 0);
    });

    return (
        <></>
    )
}

const Lab3d = ({cameraAngle}) => {

    return (
    <Canvas colorManagement camera={{position: [0,0,10], fov: 50}}>
        <ambientLight intensity={0.7} />
        <pointLight position={-10,-10,0} intensity={0.4} /> 
        <pointLight position={10,10,0} intensity={0.4} /> 
        {/* <SceneBox position={[-8,0,0]} args={[2,2,2]} cameraAngle={cameraAngle} wave={'cos'}/>
        <SceneBox position={[8,-2,0]} args={[3,3,3]} cameraAngle={cameraAngle} wave={'cos'}/>
        <SceneBox position={[-7,7,-15]} args={[3,3,3]} cameraAngle={cameraAngle} wave={'sin'}/>
        <SceneBox position={[5,6,-20]} args={[2,2,2]} cameraAngle={cameraAngle} wave={'sin'}/> */}
        <Suspense fallback={null}><SceneModel path={`../../models/monitor/scene.gltf`} position={[18,-10,-20]} scale={0.12} cameraAngle={cameraAngle} wave={`cos`}/></Suspense>
        <Suspense fallback={null}><SceneModel path={`../../models/headphones/scene.gltf`} position={[-18,-10,-20]} scale={0.1} cameraAngle={cameraAngle} wave={`cos`}/></Suspense>
        <Suspense fallback={null}><SceneModel path={`../../models/moon/scene.gltf`} position={[-10,5,-20]} scale={0.07} cameraAngle={cameraAngle} wave={`sin`}/></Suspense>
        <Suspense fallback={null}><SceneModel path={`../../models/camera/scene.gltf`} position={[8,5,-18]} scale={0.9} cameraAngle={cameraAngle} wave={`sin`}/></Suspense>

        {/* <Camera cameraAngle={cameraAngle} /> */}
    </Canvas>
    )
}

export default Lab3d